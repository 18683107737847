import 'bootstrap/dist/css/bootstrap.css'

import React from 'react';
import {
    Row, Col, Container, Media,
} from 'reactstrap';

import {Link} from "gatsby";

import '../icofont.min.css';

// Web theme color root css
import '../components/colors.css';

import '../Terms.css';
import {StaticImage} from "gatsby-plugin-image";
import Tdsheader from "../components/Tdsheader";

import '../App.css';
import '../responsive.css';
import Tdscontact from "../components/Tdscontact";
import Tdsfooter from "../components/Tdsfooter";
import Tdsaboutslider from "../components/Tdsaboutslider";

const aboutUsImage = "../images/aboutus.jpg";

export default function Safety() {
    return (
        <div className={"App"}>
            {/* Navbar  */}
            <Tdsheader active={"about"} isHome={false} />

            <h1 id={"about"} className={"heading mt-5 mb-1 ml-3 ml-sm-5"}>About Us</h1>

            {/* About us Section Start */}
            <div className="section about-box grapping" id="about">
                <Row className={"mr-3 ml-3 ml-sm-5"} >
                    <Col xs="12" sm="12" md="12" lg="6">
                        <div className="about-img">
                            <StaticImage src={aboutUsImage} alt="" className="img-fluid" />
                        </div>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="6">
                        <Tdsaboutslider />
                    </Col>
                </Row>
            </div>

            {/* Marketing Section Start*/}
            <div className="marketing-section grapping" >
                <Container>
                    <Row className="align-items-center">
                        {
                            /*
                            <Col xs="12" sm="12" md="12" lg="6">
                                <div className="marketing-img">
                                    <StaticImage src={digitalMarketingImage} alt="" className="img-fluid" />
                                </div>
                            </Col>
                             */
                        }
                        <Col xs="12">
                            <Media className="markrt-box" id={"maintenance"}>
                                <Media left href="#">
                                    <i className="icofont-list" />
                                </Media>
                                <Media body>
                                    <Media heading>
                                        Maintenance
                                    </Media>
                                    Hydraulic jack based tools are one of the most widely used equipment in facilitating the daily affairs of employees in different industries, services, and mining sectors. <br/>
                                    Everyday, many valuable workforces have to directly deal with these tools and this close contact could be a cause of various types of harmful accidents for the people and the business.
                                    A quick search in the news shows how not paying attention to the safety and health of commonly used tools such as Hydraulic jacks has led to irreparable disasters. <br/><br/>
                                    Opal Hydraulic provides two critical and professional approaches to maintain your hydraulic  jack based equipment and to increase their safety & efficiency:
                                    <ul>
                                        <li className="text-danger"><strong>1. One-time or regular inspection & testing of your hydraulic jacks.</strong></li>
                                        <li className="text-danger"><strong>2. Annual support & maintenance of all types of manual hydraulic equipment in your factories.</strong></li>
                                    </ul>
                                    We also offer vital safety training and advisory services, to provide better safety for workers and to bring peace of mind to managers.
                                    <br/><br/>

                                    <Link to={"/maintenance"}>Learn more about our maintenance program.</Link>
                                    <br/>
                                    <Link to={"/agreements#agreement-maintenance"}>Learn about our maintenance contract & agreement.</Link>

                                </Media>
                            </Media>
                            <Media className="markrt-box" id={"repair"}>
                                <Media left href="#">
                                    <i className="icofont-repair" />
                                </Media>
                                <Media body>
                                    <Media heading>
                                        Repair and Restore
                                    </Media>
                                    There are so many valuable bottle jacks made by genuine manufacturing companies in the world, such as in Germany, Sweden, USA, etc. <br/>
                                    Due to their high quality and strong infrastructure, these equipment should be repaired (not discarded) when they become faulty. <br/>
                                    In addition there are lots of expensive industrial equipment that their beating heart is a small bottle jack, despite their heavy structure. <br/>
                                    We know that you do not want to throw them away when they become defective and to replace them with low-quality products.
                                    You are right and we would love to help you avoid the unnecessary costs of buying new equipment. <br/>
                                    You may not have thought of repairing Jacks before; however, our expert and experienced team is now here to be at your service
                                    for repairing manual hydraulic jack based equipment at very reasonable and competitive prices. <br/>
                                    With more than 40 years experiences in establishment and operation of production lines of all different types of manual Hydraulic Jacks,
                                    we are prepared to offer our customers the best restoring services they deserve. <br/><br/>
                                    <strong>What you need to do:</strong><br/>
                                    Just send us a photos of your defective Hydraulic equipment and your contact detail. We will give you a free quote and a collection date. <br/><br/>
                                    <strong className="text-danger">We will collect, repair and deliver back to you. </strong>
                                    <figcaption className="blockquote-footer">Within a distance of 10 km, shipping is free.</figcaption>
                                </Media>
                            </Media>
                            <Media className="markrt-box" id={"replace"}>
                                <Media left href="#">
                                    <i className="icofont-recycle" />
                                </Media>
                                <Media body>
                                    <Media heading>
                                        Replace/Recycle
                                    </Media>
                                    <strong>A golden opportunity to renew your old hydraulic jack based equipment.</strong>
                                    <br/>
                                    Once or twice a year, every year, Opal Hydraulic arranges a limited-time special offer for customers
                                    to replace their hydraulic jack based equipment in metropolitan Melbourne.
                                    We provide an incredible opportunity for car repairers, warehouse and store owners,
                                    technical operators and anyone who uses hydraulic jacks (both for commercial and non-commercial purposes).
                                    We are encouraging everyone to trade-in their old or defective hydraulic equipment to receive brand-new alternatives
                                    and to benefit from our exclusive and massive discount rates (20-30%).
                                    <br/><br/>
                                    <em className="text-danger">We deliver to your doors without any additional fees.</em>
                                </Media>
                            </Media>

                        </Col>
                    </Row>
                </Container>
            </div>

            {/* Contact Us Section Start  */}
            <div className="section contactus grapping" id="contact">
                <Tdscontact />
            </div>

            {/* Footer  Section Start */}
            <div className="">
                <Tdsfooter/>
            </div>
        </div>
    );
}