
import React from 'react';
import {
    Row, Col
} from 'reactstrap';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './Tdsaboutslider.css';

class Tdsaboutslider extends React.Component {
    owlCarousel() {
        if(typeof window !== 'undefined') {
            const OwlCarousel = require('react-owl-carousel');

            return (
                <OwlCarousel
                    className="owl-theme"
                    loop
                    margin={10}
                    nav={false}
                    items="1"
                    dots={true}
                    lazyLoad={true}

                >
                    <div className="item">
                        <div className="title-box text-left mb-0">
                            <h2 className="mb-0">Know About Our <b>Mission</b></h2>
                            <h4 className="mt-0 mb-2 about-sub-title">We Are experts in <span>Hydraulic Jack Repairs</span></h4>
                        </div>
                        <p>
                            Australia is in the category of the developed countries. The concept of development is meaningless if infrastructure development is not taken into account.
                            Hence, dominant social approaches such as workplace and living health have prioritized some executive concepts such as "prevention and safety".
                            <br/><br/>
                            Defining, implementing, and observing safety principles is one of the indisputable principles that protect national human capital against the covert dangers in the process of daily activities of employees, and this concept,
                            as a social belief, paves the way for services that provide the highest level of safety in the society.
                            <br/><br/>
                            One of these services is the check and periodic testing (Overhaul) of any applied equipment in which the occurrence of sudden defects can lead to financial and human losses.
                            <br/><br/>
                            Hydraulic tools are one of the most widely used equipment in facilitating the daily affairs of employees in the industry, services and mining sectors,
                            which can be said to be in the third place after electrical and gas equipment.
                        </p>
                    </div>

                    <div className="item">
                        <div className="title-box text-left mb-0">
                            <h2 className="mb-0">Continued ...</h2>
                            <br/>
                        </div>
                        <p>
                            This place is because of both the rate of the use of hydraulic tools and their sensitivity and potential to bring about risks for their users.
                            We know that hydraulic jacks, which are often used to lift or move super-heavy objects, are capable of producing and exerting forces far beyond human physical strength.
                            <br/>
                            Therefore, there is no need to explain what detrimental effect their accidental fall or unwanted elasticity might bring about.
                            However, researches shows that the preventive services in hydraulic equipment have not been adequately focused on so far in a way that the poor-quality hydraulic equipment is significantly present in the Australian consumer market.
                            <br/>
                            Not only do they have a quality certificate or warranty, but also some of them do not have the name and address of the manufacturer (in spite of explicit mandatory Australian Standard 2693/2007As/Nzs).
                            <br/><br/>
                            This issue requires a social necessity that can attract the attention of the public in the consumer community through enlightenment and safety warnings.
                            This company with more than 40 years experiences in establishment and operation of production lines of all different types of manual Hydraulic Jacks,
                            has a well-written and practicable plan to provide services in this area.
                        </p>
                    </div>

                    <div className="item">
                        <div className="title-box text-left mb-0">
                            <h2 className="mb-0">Why Choose  <b>Us</b></h2>
                            <h4 className="mt-0 mb-2 about-sub-title">We Are experts in <span>Hydraulic Jack Repairs</span></h4>
                            <p>With more than 40 years of experience, we offer you the service you deserve.</p>
                        </div>
                        <div className="why-choose">
                            <Row noGutters>
                                <Col xs="6">
                                    <div className="why-box text-center">
                                        <i className="icofont-dollar"/>
                                        <h5>Cost Effective</h5>
                                    </div>
                                </Col>
                                <Col xs="6">
                                    <div className="why-box text-center">
                                        <i className="icofont-team" />
                                        <h5>Expert Team</h5>
                                    </div>
                                </Col>
                                <Col xs="6">
                                    <div className="why-box text-center">
                                        <i className="icofont-screw-driver"/>
                                        <h5>Reliable Service</h5>
                                    </div>
                                </Col>
                                <Col xs="6">
                                    <div className="why-box text-center">
                                        <i className="icofont-unique-idea" />
                                        <h5>Innovative Solutions</h5>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </OwlCarousel>
            )
        } else {
            return null
        }
    }

    render() {
        return (
            <>
                {
                    this.owlCarousel()
                }
            </>
        );

    }
}

export default Tdsaboutslider;